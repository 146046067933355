
import 'tippy.js/dist/tippy.css'
import './assets/style.css'
import './assets/vendor/bootstrap/css/bootstrap.min.css'

import HeaderView from '@/components/HeaderView.vue'
import FootView from '@/components/Foot/FootView.vue'
import { onMounted } from 'vue'

export default {
  components: { FootView, HeaderView },
  setup () {
    onMounted(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    })
  }
}
