
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FoolTools',
  setup () {
    const imgs = [
      { src: 'vue.svg', href: 'https://github.com/vuejs/vue', key: 503 },
      { src: 'fastapi.svg', href: 'https://github.com/tiangolo/fastapi', key: 312 },
      { src: 'redis.svg', href: 'https://redis.io/', key: 676 },
      { src: 'mysql.svg', href: 'https://redis.io/', key: 677 }
    ]
    return {
      imgs
    }
  }
})
