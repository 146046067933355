
import { useStatisticStore } from '@/store/modules/statisticStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'HomeFeatures',
  setup () {
    const store = useStatisticStore()
    const { total, scaffoldNum, atomAvg, heavyAvg } = storeToRefs(store)

    return {
      total, scaffoldNum, atomAvg, heavyAvg
    }
  }
}
