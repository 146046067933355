import axios, { AxiosRequestConfig, Canceler } from 'axios'
const service = axios.create({})
const pendingRequest = new Map() as Map<string, Canceler>

// removePendingRequest 取消重复请求
const removePendingRequest = (config: AxiosRequestConfig) => {
  const { url, method } = config
  const key = `${url}&${method}`
  if (pendingRequest.has(key)) {
    const cancelToken = pendingRequest.get(key) as Canceler
    cancelToken(key)
    pendingRequest.delete(key) // 请求对象中删除requestKey
  }
}

// addPendingRequest 将重复请求添加到pendingRequest中
const addPendingRequest = (config: AxiosRequestConfig) => {
  const { url, method } = config
  const key = `${url}&${method}`
  if (!pendingRequest.has(key)) {
    config.cancelToken = new axios.CancelToken((cancel) => {
      pendingRequest.set(key, cancel)
    })
  }
}

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 处理重复请求
    removePendingRequest(config)
    addPendingRequest(config)

    return config
  },
  error => {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 移除重复请求
    removePendingRequest(response.config)
    return response
  },
  error => {
    // 异常情况console，方便排查问题
    console.log('error', error)
    // 移除重复请求
    removePendingRequest(error.config || {})

    return Promise.reject(error)
  }
)

export default service
