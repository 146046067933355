<template>
<div id="statistical">
  <div class="item" v-for="(data, index) in statisticalData" :key="index">
    <img alt="" class="item-icon" :src="data.icon">
    <div class="item-number" v-html="data.number" />
    <div class="item-name">{{ data.item }}</div>
  </div>
</div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useStatisticStore } from '@/store/modules/statisticStore'
import { storeToRefs } from 'pinia/dist/pinia'
import { formatNumber } from '@/hooks'

export default {
  name: 'StatisticalView',
  setup () {
    const store = useStatisticStore()
    const { total, scaffoldNum } = storeToRefs(store)

    const _total = computed(() => {
      return formatNumber(total.value)
    })

    const _scaffoldNum = computed(() => {
      return formatNumber(scaffoldNum.value)
    })

    const statisticalData = reactive([
      { item: 'Distinct compounds', number: _total, icon: require('../../assets/icon/compound.svg') },
      { item: 'Carbon Scaffolds', number: _scaffoldNum, icon: require('../../assets/icon/frame.svg') },
      { item: 'Experiment Endpoints', number: 23, icon: require('../../assets/icon/endpoint.svg') },
      { item: 'QC Properties', number: '53 + 5', icon: require('../../assets/icon/electron.svg') },
      { item: 'Core Hours', number: '> 10<sup>7</sup>', icon: require('../../assets/icon/computer.svg') }
    ])

    return {
      statisticalData
    }
  }
}
</script>

<style scoped>

#statistical {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 2rem auto;
  width: 88%;
}

.item {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 250px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  border-radius: 15px;
  transition: linear 90ms;
}

.item:hover {
  transform: scale(1.05);
}

.item-icon {
  user-select: none;
}

.item-number {
  margin-top: 40px;
  font-family: "HarmonyOS Sans SC Light",serif;
  font-size: 2rem;
  color: #703e43;
  font-weight: bolder;
}

.item-name {
  font-family: "HarmonyOS Sans SC Light",serif;
  font-size: 1rem;
  font-weight: 500;
}

.item-icon {
  height: 70px;
}

</style>
