export function plotQuantumSunChart () {
  const data = [
    {
      name: 'Global',
      children: [
        {
          name: 'OptFreq',
          children: [
            {
              name: 'Thermodynamics',
              children: [
                { name: 'ZPE', value: 1, itemStyle: { color: '#7e2065' } },
                { name: '<span>E<sub>opt</sub></span>', value: 1, itemStyle: { color: '#1e131d' } },
                { name: '<span>E<sub>0K</sub></span>', value: 1, itemStyle: { color: '#d1c2d3' } },
                { name: 'U', value: 1, itemStyle: { color: '#815c94' } },
                { name: 'H', value: 1, itemStyle: { color: '#806d9e' } },
                { name: 'S', value: 1, itemStyle: { color: '#61649f' } },
                { name: 'G', value: 1, itemStyle: { color: '#2e317c' } },
                { name: '<span>C<sub>v</sub></span>', value: 1, itemStyle: { color: '#a7a8bd' } },
                { name: '<span>U<sub>corre</sub></span>', value: 1, itemStyle: { color: '#475164' } },
                { name: '<span>H<sub>corre</sub></span>', value: 1, itemStyle: { color: '#c8adc4' } },
                { name: '<span>G<sub>corre</sub></span>', value: 1, itemStyle: { color: '#681752' } }
              ],
              itemStyle: { color: '#c06f98' }
            },
            {
              name: 'Electronic structure',
              children: [
                { name: '<span>E<sub>homoOpt</sub></span>', value: 1, itemStyle: { color: '#ef82a0' } },
                { name: '<span>E<sub>lumoOpt</sub></span>', value: 1, itemStyle: { color: '#ed2f6a' } },
                { name: '<span>E<sub>gapOpt</sub></span>', value: 1, itemStyle: { color: '#c5708b' } },
                { name: '<span>ESE<sub>opt</sub></span>', value: 1, itemStyle: { color: '#33141e' } },
                { name: '<span>μ<sub>opt</sub></span>', value: 1, itemStyle: { color: '#621d34' } },
                { name: '<span>Θ<sub>opt</sub></span>', value: 1, itemStyle: { color: '#ef3473' } },
                { name: '<span>α<sub>opt</sub></span>', value: 1, itemStyle: { color: '#310f1b' } }
              ],
              itemStyle: { color: '#411c35' }
            }
          ],
          itemStyle: { color: '#951c48' }
        },
        {
          name: 'SPE. Cal.',
          children: [
            {
              name: 'Geometric structure',
              children: [
                { name: '<span>Volume<sub>MC</sub></span>', value: 1, itemStyle: { color: '#a7a8bd' } }
              ],
              itemStyle: { color: '#2e317c' }
            },
            {
              name: 'Thermodynamics',
              children: [
                { name: 'E', value: 1, itemStyle: { color: '#47484c' } }
              ],
              itemStyle: { color: '#131824' }
            },
            {
              name: 'Electronic structure',
              children: [
                { name: '<span>E<sub>HOMO</sub></span>', value: 1, itemStyle: { color: '#3170a7' } },
                { name: '<span>E<sub>LUMO</sub></span>', value: 1, itemStyle: { color: '#346c9c' } },
                { name: '<span>E<sub>gap</sub></span>', value: 1, itemStyle: { color: '#2474b5' } },
                { name: 'ESE', value: 1, itemStyle: { color: '#4e7ca1' } },
                { name: 'μ', value: 1, itemStyle: { color: '#126bae' } },
                { name: 'Θ', value: 1, itemStyle: { color: '#1772b4' } },
                { name: '<span>ESPC<sub>max</sub></span>', value: 1, itemStyle: { color: '#baccd9' } },
                { name: '<span>Hirshfeld<sub>max</sub></span>', value: 1, itemStyle: { color: '#619ac3' } },
                { name: '<span>CM5<sub>max</sub></span>', value: 1, itemStyle: { color: '#619ac3' } },
                { name: '<span>NPA<sub>max</sub></span>', value: 1, itemStyle: { color: '#8fb2c9' } },
                { name: '<span>ESPC<sub>min</sub></span>', value: 1, itemStyle: { color: '#5698c3' } },
                { name: '<span>Hirshfeld<sub>min</sub></span>', value: 1, itemStyle: { color: '#11659a' } },
                { name: '<span>CM5<sub>min</sub></span>', value: 1, itemStyle: { color: '#2983bb' } },
                { name: '<span>NPA<sub>min</sub></span>', value: 1, itemStyle: { color: '#1677b3' } }
              ],
              itemStyle: { color: '#15559a' }
            }
          ],
          itemStyle: { color: '#1f2040' }
        },
        {
          name: 'QMSA.',
          children: [
            {
              name: 'Geometric structure',
              children: [
                { name: '<span>Volume<sub>IMT</sub></span>', value: 1, itemStyle: { color: '#1ba784' } },
                { name: 'Density', value: 1, itemStyle: { color: '#66c18c' } },
                { name: 'SA', value: 1, itemStyle: { color: '#207f4c' } }
              ],
              itemStyle: { color: '#248067' }
            },
            {
              name: 'Electronic structure',
              children: [
                { name: '<span>SA<sup>+</sup></span>', value: 1, itemStyle: { color: '#dfecd5' } },
                { name: '<span>SA<sup>-</sup></span>', value: 1, itemStyle: { color: '#9fa39a' } },
                { name: '<span>SA<sub>nonpolar</sub></span>', value: 1, itemStyle: { color: '#b2cf87' } },
                { name: '<span>SA<sub>polar</sub></span>', value: 1, itemStyle: { color: '#96c24e' } },
                { name: '<span>ESP<sub>μ</sub></span>', value: 1, itemStyle: { color: '#f0f5e5' } },
                { name: '<span>ESP<sub>μ</sub><sup>+</sup></span>', value: 1, itemStyle: { color: '#b7d07a' } },
                { name: '<span>ESP<sub>μ</sub><sup>-</sup></span>', value: 1, itemStyle: { color: '#373834' } },
                { name: '<span>ESP<sub>σ</sub></span>', value: 1, itemStyle: { color: '#bacf65' } },
                { name: '<span>ESP<sub>σ</sub><sup>+</sup></span>', value: 1, itemStyle: { color: '#e2e7bf' } },
                { name: '<span>ESP<sub>σ</sub><sup>-</sup></span>', value: 1, itemStyle: { color: '#bec936' } },
                { name: '<span>ESP<sub>max</sub></span>', value: 1, itemStyle: { color: '#e2d849' } },
                { name: '<span>ESP<sub>min</sub></span>', value: 1, itemStyle: { color: '#ad9e5f' } },
                { name: 'ν', value: 1, itemStyle: { color: '#fbda41' } },
                { name: '<span>νESP<sub>σ</sub></span>', value: 1, itemStyle: { color: '#e2c027' } },
                { name: 'Pi', value: 1, itemStyle: { color: '#887322' } },
                { name: 'MPI', value: 1, itemStyle: { color: '#5b4913' } }
              ],
              itemStyle: { color: '#485b4d' }
            }
          ],
          itemStyle: { color: '#1a3b32' }
        }
      ],
      itemStyle: { color: '#7c1823' }
    },
    {
      name: 'Local',
      children: [
        {
          name: 'SPE. Cal.',
          children: [
            {
              name: 'Electronic structure',
              children: [
                { name: 'ESPC', value: 1, itemStyle: { color: '#e46828' } },
                { name: 'Hirshfeld', value: 1, itemStyle: { color: '#fc6315' } },
                { name: 'CM5', value: 1, itemStyle: { color: '#b7511d' } },
                { name: 'NPA', value: 1, itemStyle: { color: '#ea8958' } },
                { name: 'Wiberg', value: 1, itemStyle: { color: '#edc3ae' } }
              ],
              itemStyle: { color: '#de2a18' }
            }
          ],
          itemStyle: { color: '#ef632b' }
        }
      ],
      itemStyle: { color: '#732e12' }
    }
  ]

  return {
    title: {
      subtext: 'More Detail',
      subtextStyle: {
        align: 'center',
        fontSize: 18,
        fontFamily: 'HarmonyOS Sans SC'
      },
      sublink: '/explanation'
    },
    series: {
      type: 'sunburst',
      nodeClick: 'rootToNode',
      data: data,
      tooltip: {
        trigger: 'item'
      },
      label: {
        fontSize: 11
      },
      radius: [0, '95%'],
      sort: undefined,
      emphasis: {
        focus: 'ancestor'
      },
      levels: [
        {},
        {
          r0: '10%',
          r: '25%',
          itemStyle: {
            borderWidth: 2
          },
          label: {
            rotate: 'tangential'
          }
        },
        {
          r0: '25%',
          r: '35%',
          label: {
            rotate: 'tangential'
          }
        },
        {
          r0: '35%',
          r: '73%',
          label: {
            rotate: 'radial'
          }
        },
        {
          r0: '73%',
          r: '77%',
          label: {
            position: 'outside',
            padding: 3,
            silent: false,
            formatter: function (value: {data: {name: string}}) {
              const name = value.data.name
              const title = name.match(/(?<=<span>).*?(?=<)/g)
              if (!title) {
                return `{a|${name}}`
              }
              const subName = name.match(/(?<=<sub>).*?(?=<)/g)
              const supName = name.match(/(?<=<sup>).*?(?=<)/g)

              if (subName && supName) {
                return [`{a|${title[0]}}`, `{b|${subName[0]}}`, `{c|${supName[0]}}`].join('')
              }

              if (subName) {
                return [`{a|${title[0]}}`, `{b|${subName[0]}}`].join('')
              }

              if (supName) {
                return [`{a|${title[0]}}`, `{c|${supName[0]}}`].join('')
              }

              return `{a|${title[0]}}`
            },
            rich: {
              a: {
                fontSize: 12
              },
              b: {
                fontSize: 8,
                verticalAlign: 'bottom'
              },
              c: {
                fontSize: 8,
                verticalAlign: 'top'
              }
            }
          },
          itemStyle: {
            borderWidth: 3
          }
        }
      ]
    }
  }
}
