import { defineStore } from 'pinia'
import { useRootStore } from '@/store'
import axios from 'axios'

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

interface molInfoCard {
  molID: string
  imgSVG: string
}

export const useBrowseStore = defineStore('browseStore', {
  state: () => {
    return {
      mol2show: [] as Array<molInfoCard>,
      molSimilarity: [] as Array<number>,
      molNum: 0,
      numPerPage: 24,
      propItems: '',
      currPage: 1,
      pageList: [1, 2, 3, 4, 5] as Array<number>,
      onSearchMode: false,
      searchedSmiles: '',
      molIDsToShow: '',
      tanimoto: {}
    }
  },
  actions: {
    async changeMolToShow (skip: number) {
      const root = useRootStore()

      root.running = true
      const resp = await axios({
        url: `${rootUrl}/browse/molecules`,
        method: 'get',
        params: { skip, limit: this.numPerPage, items: this.propItems, molIDs: this.molIDsToShow }
      })
      this.mol2show = resp.data
      if (this.onSearchMode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.mol2show.sort((a, b) => -this.tanimoto[a.molID] + this.tanimoto[b.molID])
      }
      root.running = false
    },
    // 统计出符合条件的化合物的数量
    async updateMolNumber (items: string) {
      const resp = await axios({
        url: `${rootUrl}/mol_number`,
        method: 'POST',
        data: { items }
      })
      this.molNum = resp.data.total
    },
    async updatePageList (currPage: number) {
      function range (start: number, stop: number, step = 1): Array<number> {
        return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)
      }
      const totalPage = Math.ceil(this.molNum / this.numPerPage)
      this.currPage = currPage

      if (currPage >= 4) {
        const stop = currPage + 2
        this.pageList = range(
          currPage - 2,
          stop <= totalPage ? stop + 1 : totalPage + 1
        )
      } else {
        this.pageList = range(1, 6)
      }
    }
  },
  getters: {
    totalPage (state) {
      return Math.ceil(state.molNum / state.numPerPage)
    }
  }
})
