import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueTippy from 'vue-tippy'

const pinia = createPinia()
createApp(App)
  .use(pinia)
  .use(router)
  .use(VueTippy, {
    directive: 'tippy',
    flipDuration: 0,
    popperOptions: {
      modifiers: {
        preventOverflow: {
          enabled: false
        }
      }
    }
  })
  .mount('#app')
