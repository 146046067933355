
import { plotSummaryBubble } from '@/views/CarouselPlot/scripts/plotSummaryBubble'
import { plotWordPlot } from '@/views/CarouselPlot/scripts/plotWordCloud'
import { plotFrameCloud } from '@/views/CarouselPlot/scripts/plotFrameCloud'
import * as echarts from 'echarts'

import { onMounted, ref } from 'vue'
import { plotAtomDist } from '@/views/CarouselPlot/scripts/plotAtomDist'
import MaskView from '@/components/MaskView.vue'
import LoaderView from '@/components/LoaderView.vue'
import { plotQuantumSunChart } from '@/views/CarouselPlot/scripts/plotQuantumSunChart'

export default {
  name: 'CarouselPlot',
  components: { LoaderView, MaskView },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup () {
    const chartDom = ref(null)
    let myChart: echarts.ECharts

    onMounted(async () => {
      if (chartDom.value) {
        const elem = chartDom.value as HTMLDivElement
        myChart = echarts.init(elem, undefined)
        await plot()
      }
    })

    const option = ref()
    const currPlot = ref(0)
    const totalPlot = 5

    const descriptions = [
      'A sunburst chart displaying the sources and classifications of 53 global and 5 local quantum chemical properties in the QuanDB.',
      'A bubble chart displaying sample size of experimental data for three major categories.',
      'A word cloud displaying the sample size for each experimental endpoint.',
      'Display the distribution of heavy atoms (bar) and total atoms (line) in the database.',
      'A cloud chart displaying the number of carbon scaffolds in the QuanDB'
    ]

    const introductions = [
      'Click to expand the target and all its child-item.',
      'The larger the sample size, the larger the bubble.',
      'The larger the sample size, the larger the font size for the word, hover the mouse over item to display the specific number.',
      'Hover the mouse over item to display the specific number.',
      'The larger the sample size, the larger the scaffold images, hover the mouse over item to display the specific number.'
    ]

    async function plot () {
      myChart.clear()
      myChart.showLoading()
      switch (currPlot.value) {
        case 0:
          option.value = plotQuantumSunChart()
          break
        case 1:
          option.value = await plotSummaryBubble()
          break
        case 2:
          option.value = await plotWordPlot()
          break
        case 3:
          option.value = await plotAtomDist()
          break
        case 4:
          option.value = await plotFrameCloud(chartDom.value as unknown as HTMLElement)
          break
      }
      myChart.setOption(option.value)
      myChart.hideLoading()
    }

    async function nextPlot () {
      currPlot.value++
      currPlot.value = currPlot.value < totalPlot ? currPlot.value : 0
      const selects = buttons.value as unknown as HTMLUListElement
      Array.from(selects.children).forEach((elem, index) => {
        if (currPlot.value === index) {
          elem.classList.add('active')
          return
        }
        elem.classList.remove('active')
      })
      await plot()
    }

    async function prevPlot () {
      currPlot.value--
      currPlot.value = currPlot.value >= 0 ? currPlot.value : totalPlot - 1
      const selects = buttons.value as unknown as HTMLUListElement
      Array.from(selects.children).forEach((elem, index) => {
        if (currPlot.value === index) {
          elem.classList.add('active')
          return
        }
        elem.classList.remove('active')
      })
      await plot()
    }

    const buttons = ref(null)
    async function select ($event: Event, index: number) {
      const selects = buttons.value as unknown as HTMLUListElement
      Array.from(selects.children).forEach(elem => {
        elem.classList.remove('active')
      })
      const target = $event.target as HTMLElement
      (target.parentElement as HTMLElement).classList.add('active')
      currPlot.value = index - 1
      await plot()
    }

    return {
      descriptions,
      introductions,
      currPlot,
      buttons,
      chartDom,
      totalPlot,
      nextPlot,
      prevPlot,
      select
    }
  }
}
