<template>
  <div id="welcome">
    <div class="my-container">
      <div class="description">
        <h1>Quantum Chemical Properties Database</h1>
        <p>{{ description }}</p>
        <router-link to="/documentation">
          <button class="button-24">More</button>
        </router-link>
      </div>
      <div class="abstract-graph"></div>
    </div>
  </div>
</template>

<script lang="js">
import { computed } from 'vue'
import { useStatisticStore } from '@/store/modules/statisticStore'
import { storeToRefs } from 'pinia/dist/pinia'

export default {
  name: 'HomeWelcome',
  setup () {
    const store = useStatisticStore()
    const { total } = storeToRefs(store)

    const description = computed(() => {
      return 'QuanDB is a database composed entirely of the quantum chemical properties with ' +
          'diverse organic molecular entities, all of which are pretreatment and manual cleaning strictly and carefully. ' +
          `QuanDB currently contains ${total.value.toLocaleString()} molecules with endpoints relating to biological activity, toxicity and ` +
          'material properties, each molecule has no more than 40 atoms, and atomic types include H, C, N, O, P, S ' +
          'and halogens. For each molecule, 58 quantum chemical properties (53 global and 5 local) are calculated using ' +
          'the high-precision density functional theory (DFT), covering the geometric structure, electronic structure ' +
          'and thermodynamic properties. We believe that QuanDB will serve as a new reliable, high-quality benchmark ' +
          'for quantum chemical properties, enriching the information characterization of the three-dimensional stable ' +
          'conformation of molecules, and further accelerating the development of novel prospective drugs and materials.'
    })

    return {
      description
    }
  }
}
</script>

<style scoped>

#welcome {
  background-color: #b5c8e9;
  background-image: linear-gradient(#a71725, #486BA3, #FFFFFF);
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-container {
  /*position: absolute;*/
  /*height: 90vh;*/
  /*width: 95vw;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.description {
  width: 50%;
  display: flex;
  /*position: absolute;*/
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
  flex-direction: column;
  align-items: center;
}

.description h1 {
  font-family: "HarmonyOS Sans SC Black", Poppins, Calibri, Arial, sans-serif;
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bolder;
  color: aliceblue;
}

.description p {
  font-family: "HarmonyOS Sans SC Medium", Poppins, Calibri, Arial, sans-serif;
  font-size: 1.2rem;
  text-align: justify;
  font-weight: 600;
  /*line-height: 1;*/
  color: aliceblue;
}

.abstract-graph {
  background-image: url("../../assets/image/frame2-min.png");
  width: 510px;
  height: 306px;
  background-size: cover;
}

.button-24 {
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 233px;
  margin: 50px;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
}
</style>
