<template>
  <hr class="hr-text" :data-content="content">
</template>

<script>
export default {
  name: 'HrLine',
  props: {
    content: String
  }
}
</script>

<style scoped>

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: transparent;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

</style>
