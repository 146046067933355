import { convertRange, randomColor } from '@/views/CarouselPlot/scripts/utils'
import service from '@/store/modules/debounce'

interface BubbleData {
  name: string,
  value: number,
  symbolSize: number
  id: string,
  itemStyle: {
    color: string
  }
}

let rootUrl: string
if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

async function obtainBubbleData (): Promise<{ bioactivity: number, toxicity: number, cp: number }> {
  const resp = await service({
    method: 'get',
    url: `${rootUrl}/plot/prop/bubble`
  })

  return resp.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function plotSummaryBubble () {
  const bubbleData = await obtainBubbleData()

  const values = [
    { name: 'Bioactivity', value: bubbleData.bioactivity },
    { name: 'Toxicity', value: bubbleData.toxicity },
    { name: 'Chem. & Phys.', value: bubbleData.cp }
  ]

  debugger

  const maxNum = Math.max(...values.map(x => x.value))
  const minNum = Math.min(...values.map(x => x.value))

  const right = 180
  const left = 150

  const data: Array<BubbleData> = []

  for (let i = 0; i < values.length; i++) {
    const temp = {
      name: values[i].name,
      value: values[i].value,
      symbolSize: convertRange(values[i].value, [maxNum, minNum], [right, left]),
      id: `${i}`,
      itemStyle: {
        color: randomColor()
      }
    }

    data.push(temp)
  }

  return {
    series: [
      {
        type: 'graph',
        layout: 'force',
        animation: false,
        data,
        force: {
          repulsion: 500,
          edgeLength: 5
        },
        emphasis: {
          label: false,
          focus: 'adjacency'
        },
        roam: true,
        label: {
          show: true,
          position: 'inside',
          formatter: (params: Array<unknown>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return `{a|${params.name}}\n{b|${params.data.value.toLocaleString()}}`
          },
          rich: {
            a: {
              // lineHeight: 10,
              fontSize: 20
            },
            b: {
              fontSize: 18,
              align: 'center',
              lineHeight: 30
            }
          }
        }
        // itemStyle: {
        //   shadowColor: 'rgba(0, 0, 0, 0.5)',
        //   shadowBlur: 30
        // }
      }
    ]
  }

  // myChart.setOption(option)

  // option && myChart.setOption(option)
}
