<template>
  <div class="home">
    <HomeWelcome ref="welcome" />
    <div class="my-container">
      <StatisticalView/>
      <HrLine content="" />
      <CarouselPlot />
      <HomeFeatures />
      <HomeVersion />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import HomeWelcome from '@/views/Home/HomeWelcome'
import CarouselPlot from '@/views/CarouselPlot/CarouselPlot'
import StatisticalView from '@/views/Home/StatisticalView'
import HrLine from '@/components/HrLine'
import HomeVersion from '@/views/Home/HomeVersion'
import HomeFeatures from '@/views/Home/HomeFeatures'
import { useStatisticStore } from '@/store/modules/statisticStore'

export default defineComponent({
  name: 'HomeView',
  components: {
    HomeFeatures,
    HomeVersion,
    HrLine,
    StatisticalView,
    HomeWelcome,
    CarouselPlot
    // HomeWelcome
  },
  setup () {
    const welcome = ref(null)
    const store = useStatisticStore()

    onMounted(() => {
      store.updatedAllInfo()
    })

    onMounted(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    })

    return {
      welcome
    }
  }
})
</script>

<style scoped>

.home {
  margin-top: 116px;
}

.my-container {
  margin-bottom: 3rem;
}

#features {
  margin: 3rem auto;
}

</style>
