import { defineStore } from 'pinia'

export const useRootStore = defineStore('root', {
  state: () => {
    return {
      status: 200,
      running: false,
      isDrawing: false,
      asyncFinished: true,
      rdkitLoaded: false
    }
  }
})
