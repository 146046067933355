import 'echarts-wordcloud'
import service from '@/store/modules/debounce'

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getEndpointCloudData () {
  const resp = await service({
    method: 'get',
    url: `${rootUrl}/plot/cloud/endpoint`
  })
  return resp.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getElementCloudData () {
  const resp = await service({
    method: 'get',
    url: `${rootUrl}/plot/cloud/element`
  })
  const out = []
  for (const [k, v] of Object.entries(resp.data)) {
    const temp = {
      name: k,
      value: v
    }
    out.push(temp)
  }
  return out
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function plotWordPlot (source = 'endpoint') {
  const data = source === 'endpoint'
    ? await getEndpointCloudData()
    : await getElementCloudData()

  return {
    tooltip: {
      show: true
    },
    series: [{
      type: 'wordCloud',
      // The shape of the "cloud" to draw. Can be any polar equation represented as a
      // callback function, or a keyword present. Available presents are circle (default),
      // cardioid (apple or heart shape curve, the most known polar equation), diamond (
      // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

      shape: 'diamond',

      // A silhouette image which the white area will be excluded from drawing texts.
      // The shape option will continue to apply as the shape of the cloud to grow.

      // maskImage: maskImage,

      // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
      // Default to be put in the center and has 75% x 80% size.

      left: 'center',
      top: 'center',
      width: '90%',
      height: '80%',
      right: null,
      bottom: null,

      // Text size range which the value in data will be mapped to.
      // Default to have minimum 12px and maximum 60px size.

      sizeRange: [24, 100],

      // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

      rotationRange: [-90, 90],
      rotationStep: 45,

      // size of the grid in pixels for marking the availability of the canvas
      // the larger the grid size, the bigger the gap between words.

      gridSize: 8,

      // set to true to allow word being draw partly outside of the canvas.
      // Allow word bigger than the size of the canvas to be drawn
      drawOutOfBound: false,

      // If perform layout animation.
      // NOTE disable it will lead to UI blocking when there is lots of words.
      layoutAnimation: true,

      // Global text style
      textStyle: {
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        // Color can be a callback function or a color string
        color: function () {
          // Random color
          return 'rgb(' + [
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160),
            Math.round(Math.random() * 160)
          ].join(',') + ')'
        }
      },
      emphasis: {
        focus: 'self',

        textStyle: {
          shadowBlur: 10,
          shadowColor: '#333'
        }
      },

      // Data is an array. Each array item must have name and value property.
      data: data
    }]
  }
}
