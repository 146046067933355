
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FootAbout',
  setup () {
    const year = computed(() => {
      const date = new Date()
      return date.getFullYear()
    })

    const description =
        ref('QuanDB is a reliable and high-quality quantum chemical properties database, providing 58 ' +
            'quantum chemical properties for each molecule, which can greatly enrich the information of ' +
            'three-dimensional stable conformation characterization for molecules. Thus effectively facilitating ' +
            'the development of novel drugs and materials. For more details, please refer to our ' +
            '<a href="https://jcheminf.biomedcentral.com/articles/10.1186/s13321-024-00843-y">paper</a>.'
        )

    return {
      year,
      description
    }
  }
})
