<template>
  <div id="version">
    <p class="version">Current Release: QuanDB 1</p>
    <p class="license">
      Provided under a <a href="https://creativecommons.org/licenses/by-sa/3.0/">Creative Commons Attribution-ShareAlike 3.0 Unported license</a>
    </p>
    <p class="date">Last Update on 30-11-23 21:00 (UTC +8:00)</p>
  </div>
</template>

<script>
export default {
  name: 'HomeVersion'
}
</script>

<style scoped>

#version {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.9;
  /*margin: 70px;*/
  user-select: none;
}

.version {
  font-family: "HarmonyOS Sans SC Light",Arial,serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.license, .date {
  font-family: "HarmonyOS Sans SC Thin",serif;
  font-weight: 200;
}

.license a {
  text-decoration: none !important;
}

.license a:hover {
  text-decoration: underline !important;
}

</style>
