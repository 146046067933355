
import { defineComponent } from 'vue'
import FootAbout from '@/components/Foot/FootAbout.vue'
import FootLinks from '@/components/Foot/FootLinks.vue'
import FoolTools from '@/components/Foot/FoolTools.vue'
import OccupyView from '@/components/OccupyView.vue'
import FootMap from '@/components/Foot/FootMap.vue'

export default defineComponent({
  name: 'FootView',
  components: { FootMap, OccupyView, FoolTools, FootLinks, FootAbout }
})
