import service from '@/store/modules/debounce'

interface AtomDistResp {
  nAtom: number[],
  freq: [number, number][]
}

interface AtomDist {
  nAtom: number[],
  heavyDist: number[],
  allDist: number[]
}

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

async function getAtomDist (): Promise<AtomDist | undefined> {
  const resp = await service({
    method: 'POST',
    url: `${rootUrl}/atom_dist`
  })

  if (resp.data) {
    const data = resp.data as AtomDistResp
    const out = {
      nAtom: data.nAtom,
      heavyDist: [] as number[],
      allDist: [] as number[]
    }

    for (let i = 0; i < data.freq.length; i++) {
      out.allDist.push(data.freq[i][0])
      out.heavyDist.push(data.freq[i][1])
    }

    return out as AtomDist
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function plotAtomDist () {
  const atomDist = await getAtomDist()

  return {
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
      // formatter: 'N<sub>{b}</sub>:&nbsp;&nbsp;&nbsp;&nbsp;<strong>{c}</strong>'
      // formatter: (params: Array<unknown>) => {
      //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //   // @ts-ignore
      //   return `N<sub>${params.name}</sub>:&nbsp;&nbsp;&nbsp;&nbsp;<strong>${params.data.toLocaleString()}</strong>`
      // }
    },
    legend: {},
    xAxis: [
      {
        type: 'category',
        data: atomDist?.nAtom,
        name: 'Number of atoms',
        nameLocation: 'center',
        nameGap: 20,
        axisLabel: {
          fontSize: 8
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Absolute frequency',
        nameLocation: 'center',
        nameGap: 50,
        axisLabel: {
          fontSize: 8
        }
      }
    ],
    series: [
      {
        data: (atomDist && atomDist.heavyDist) || [],
        name: 'Heavy Atom',
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        },
        label: {
          position: 'top',
          rotate: 90,
          show: true,
          fontSize: 9,
          backgroundColor: 'rgba(180, 180, 180, 0.2)',
          color: 'black',
          fontWeight: 'bold',
          fontFamily: 'arial',
          offset: [10, 4]
        },
        roam: true
      },
      {
        data: (atomDist && atomDist.allDist) || [],
        name: 'All Atom',
        type: 'line',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        },
        roam: true
      }
    ]
  }
}
