<template>
  <a href="https://www.revolvermaps.com/livestats/50fxzt6xiu9/"><img src="//rf.revolvermaps.com/h/m/a/0/ff0000/128/0/50fxzt6xiu9.png" width="256" height="128" alt="Map" style="border:0;"></a>
</template>

<script>
export default {
  name: 'FootMap'
}
</script>

<style scoped>

</style>
