import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/browse',
    name: 'browse',
    component: () => import('../views/Browse/BrowseView.vue')
  },
  {
    path: '/compound/:qid',
    name: 'compound',
    component: () => import('../views/MolContent/MolContent.vue')
  },
  {
    path: '/explanation',
    name: 'explanation',
    component: () => import('../views/AboutPage/AboutExplanation.vue')
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('../views/AboutPage/AboutDocumentation.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/AboutPage/AboutStatistics.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Concat/ConcatView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/DownloadPage/DownloadView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'active',
  routes
})

export default router
