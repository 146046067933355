<template>
  <div id="drawer">
    <div id="myEditor" style=""></div>
    <div class="btns">
      <button type="button" class="btn btn-outline-success active" @click.prevent="obtainSmiles">OK</button>
      <button type="button" class="btn btn-outline-danger active" @click.prevent="closeEditor">Cancel</button>
    </div>
  </div>
</template>

<script>
import { useRootStore } from '@/store'
import { useBrowseStore } from '@/store/modules/browseStore'
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'DrawerView',
  setup () {
    onMounted(() => {
      // eslint-disable-next-line no-undef
      document.moledit = new ChemicalView('', 'myEditor')
    })

    async function obtainSmiles () {
      const browseStore = useBrowseStore()
      browseStore.searchedSmiles = document.moledit.getSmiles()
      closeEditor()
    }

    function closeEditor () {
      const root = useRootStore()
      root.isDrawing = false
    }

    return {
      obtainSmiles,
      closeEditor
    }
  }
})
</script>

<style scoped>

#drawer {
  position: fixed;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#myEditor {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 5px;
}

.btns button {
  position: relative;
  width: 15%;
}

.btns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin-top: 2rem;
}

</style>
