import { defineStore } from 'pinia'
import { useBrowseStore } from '@/store/modules/browseStore'
import axios from 'axios'

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

export const useSearchStore = defineStore('searchStore', {
  state: () => {
    return {
      searchFinished: true
    }
  },
  actions: {
    async searchWithSimilarity (smiles: string, skip: number) {
      const browseStore = useBrowseStore()

      this.searchFinished = false
      browseStore.onSearchMode = false

      const resp = await axios({
        url: `${rootUrl}/search/similarity`,
        method: 'POST',
        data: { smiles, skip: skip, limit: browseStore.numPerPage, items: browseStore.propItems }
      })

      const tanimoto = resp.data
      const molIDs = Object.keys(tanimoto)
      browseStore.molIDsToShow = molIDs.join(',')
      browseStore.tanimoto = tanimoto
      browseStore.searchedSmiles = smiles
      browseStore.onSearchMode = true
      await browseStore.changeMolToShow(0)

      this.searchFinished = true
    }
  }
})
