import { convertRange } from '@/views/CarouselPlot/scripts/utils'
import service from '@/store/modules/debounce'

interface FrameCloudData {
  name: string,
  value: number,
  symbolSize: number,
  id: string,
  tooltip: {
    show: boolean,
    formatter: string,
    backgroundColor: string,
    textStyle: {
      color: string
    }
  },
  symbol: string,
  fixed?: boolean,
  x?: number,
  y?: number
}

interface FrameResponseData {
  scaffold: string,
  freq: number
}

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

async function getFrameCloudData (): Promise<Array<FrameResponseData>> {
  const resp = await service({
    method: 'get',
    url: `${rootUrl}/plot/cloud/framework`
  })

  return resp.data
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function plotFrameCloud (elem: HTMLElement) {
  const respData = await getFrameCloudData()

  const maxNum: number = respData[0].freq
  const minNum: number = respData[respData.length - 1].freq
  const left = 25
  const right = 65

  const data: Array<FrameCloudData> = []
  for (let i = 0; i < respData.length; i++) {
    const temp: FrameCloudData = {
      name: respData[i].scaffold,
      value: respData[i].freq,
      symbolSize: convertRange(respData[i].freq, [maxNum, minNum], [right, left]),
      id: `${i + 1}`,
      symbol: `image://https://quandb.cmdrg.com/assets/scaffolds/scaffold${i + 1}.svg`,
      tooltip: {
        show: true,
        formatter: '{b}: {c}',
        backgroundColor: 'grey',
        textStyle: {
          color: 'white'
        }
      }
    }

    if (i === 0) {
      temp.fixed = true
      temp.y = elem.clientHeight / 2
      temp.x = elem.clientWidth / 2
    }

    data.push(temp)
  }

  return {
    series: [
      {
        type: 'graph',
        layout: 'force',
        animation: false,
        data: data,
        force: {
          // initLayout: 'circular'
          // gravity: 0
          repulsion: 12,
          edgeLength: 5
        },
        edges: [],
        emphasis: {
          label: false
          // focus: 'adjacency'
        },
        roam: true,
        draggable: true
      }
    ],
    tooltip: {}
  }
}
