
import { useRouter } from 'vue-router'
import { useRootStore } from '@/store'
import { useSearchStore } from '@/store/modules/searchStore'
import { useBrowseStore } from '@/store/modules/browseStore'
import { ref, defineComponent, computed, watch, onBeforeMount } from 'vue'
import MaskView from '@/components/MaskView.vue'
import LoaderView from '@/components/LoaderView.vue'
import initRDKit from '@/components/utils/initRDKit'

export default defineComponent({
  name: 'SearchBar',
  components: { LoaderView, MaskView },
  props: {
    touchTop: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const root = useRootStore()
    const searchStore = useSearchStore()
    const router = useRouter()

    const smiles = ref('')
    const drawSmiles = computed(() => {
      const browseStore = useBrowseStore()
      return browseStore.searchedSmiles
    })

    watch(drawSmiles, (newSmiles) => {
      smiles.value = newSmiles
    })

    function openEditor () {
      root.isDrawing = true
    }

    const exampleSmiles = 'NS(=O)(=O)c1cc2c(cc1Cl)NC(CSCc1ccccc1)=NS2(=O)=O'
    async function runExample () {
      smiles.value = exampleSmiles
      await search()
    }

    onBeforeMount(() => {
      const root = useRootStore()
      if (root.rdkitLoaded) {
        return
      }
      initRDKit()
        .then(() => {
          root.rdkitLoaded = true
          try {
            const mol = window.RDKit.get_mol('c1ccccc1')
            console.log(mol)
          } catch (err) {
            console.error(err)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    })

    async function search () {
      if (smiles.value) {
        const mol = window.RDKit.get_mol(smiles.value)
        if (!!mol && mol.is_valid()) {
          await searchStore.searchWithSimilarity(smiles.value, 0)

          const browseStore = useBrowseStore()
          browseStore.currPage = 1
          browseStore.pageList = [1, 2, 3, 4, 5]
          await router.push({ name: 'browse' })
          return
        }
        alert('Invalid SMILES!')
        smiles.value = ''
      }
    }

    return {
      smiles,
      exampleSmiles,
      openEditor,
      runExample,
      search,
      searchStore
    }
  }
})
