import { defineStore } from 'pinia'
import service from '@/store/modules/debounce'

let rootUrl: string

if (process.env.NODE_ENV === 'development') {
  rootUrl = 'http://10.1.1.100:5555'
} else {
  rootUrl = 'https://quandb.cmdrg.com/api'
}

export const useStatisticStore = defineStore('statisticStore', {
  state: () => {
    return {
      total: 0,
      scaffoldNum: 0,
      atomAvg: 0,
      heavyAvg: 0,
      nC: 0,
      nN: 0,
      nO: 0,
      nP: 0,
      nS: 0,
      nF: 0,
      nCl: 0,
      nBr: 0,
      avgC: 0,
      avgN: 0,
      avgO: 0,
      avgP: 0,
      avgS: 0,
      avgF: 0,
      avgCl: 0,
      avgBr: 0,
      requestStatus: false
    }
  },
  actions: {
    async updatedMolNum () {
      const resp = await service({
        url: `${rootUrl}/mol_number`,
        method: 'POST',
        data: {
          items: ''
        }
      })
      this.total = resp.data.total
    },
    async updatedScaffoldNum () {
      const resp = await service({
        url: `${rootUrl}/scaffold_number`,
        method: 'POST'
      })
      this.scaffoldNum = resp.data.scaffoldNum
    },
    async updatedAtomInfo () {
      const resp = await service({
        url: `${rootUrl}/atom_number`,
        method: 'POST'
      })
      const { atomAvg, heavyAvg } = resp.data
      this.atomAvg = atomAvg
      this.heavyAvg = heavyAvg
    },
    async updatedHeavyAtomDetail () {
      const resp = await service({
        url: `${rootUrl}/heavy_atom_detail`,
        method: 'POST'
      })
      const { nC, nN, nO, nP, nS, nF, nCl, nBr } = resp.data
      this.nC = nC
      this.nN = nN
      this.nO = nO
      this.nP = nP
      this.nS = nS
      this.nF = nF
      this.nCl = nCl
      this.nBr = nBr
    },
    async updatedAllInfo () {
      this.requestStatus = false
      await this.updatedMolNum()
      await this.updatedScaffoldNum()
      await this.updatedAtomInfo()
      await this.updatedHeavyAtomDetail()
      this.requestStatus = true
    }
  }
})
