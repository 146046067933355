
import DrawerView from './DrawerView.vue'
import SearchBar from './SearchBar.vue'
import { computed, onMounted, ref } from 'vue'
import { useRootStore } from '@/store'
export default {
  name: 'HeaderView',
  components: { SearchBar, DrawerView },
  setup () {
    const touchTop = ref(true)
    onMounted(() => {
      window.onscroll = function () {
        const htmlHeight = document.body.scrollHeight || document.documentElement.scrollHeight
        const clientHeight = document.body.clientHeight || document.documentElement.clientHeight
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        touchTop.value = scrollTop + clientHeight === htmlHeight
      }
    })

    const root = useRootStore()
    const showEditor = computed(() => root.isDrawing)

    return {
      touchTop,
      showEditor
    }
  }
}
